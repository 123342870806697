td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

td.column-originalUrl {
  max-width: 15rem;
}

.RaSidebar-fixed-36 {
  width: inherit;
}
